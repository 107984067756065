/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

import Section from "src/components/Section"
import SectionVertical from "src/components/SectionVertical"
import Container from "src/components/Container"
import HubLinks from "src/components/HubLinks"

const LinkListHubs = (props) => {
  return (
    <SectionVertical
      as="section"
      sx={{
        variant: "section.large",
      }}
    >
      {props.sectionHeading && (
        <Section>
          <Container>
            <h2
              id={props.sectionHeadingId}
              sx={{ variant: "text.sectionHeading" }}
            >
              {props.sectionHeading}
            </h2>
          </Container>
        </Section>
      )}
      {props.active && <HubLinks />}
    </SectionVertical>
  )
}

LinkListHubs.propTypes = {
  sectionHeading: PropTypes.string.isRequired,
  active: PropTypes.bool,
}

export default LinkListHubs
