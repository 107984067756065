/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticQuery, graphql } from "gatsby"

import SectionNarrow from "src/components/SectionNarrow"
import Container from "src/components/Container"
import Columns from "src/components/Columns"
import Column from "src/components/Column"
import LinkCard from "src/components/LinkCard"

const HubLinks = () => {
  return (
    <StaticQuery
      query={graphql`
        query GetHubsLinks {
          wpgraphql {
            taxHubs(where: { hideEmpty: true, orderby: NAME }) {
              nodes {
                name
                link
                acfThemeColor {
                  themeColor
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { nodes } = data.wpgraphql.taxHubs

        return nodes.length > 0 ? (
          <SectionNarrow>
            <Container>
              <Columns
                sx={{ m: [-1, -1], flexDirection: ["row", "row", "row"] }}
              >
                {nodes.map((hub, index) => {
                  const link = {
                    title: hub.name,
                    link: hub.link,
                  }
                  return (
                    <Column
                      key={index}
                      sx={{
                        p: [1, 1],
                        width: ["100%", "50%", "33.3333%", false, "25%"],
                      }}
                    >
                      <LinkCard
                        link={link}
                        theme_colour={hub.acfThemeColor.themeColor}
                      />
                    </Column>
                  )
                })}
              </Columns>
            </Container>
          </SectionNarrow>
        ) : null
      }}
    />
  )
}

export default HubLinks
